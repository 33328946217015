
$buttonPadding: .5rem 1rem;


$buttonIconOnlyWidth: 2.357rem;


$buttonIconOnlyPadding: .5rem 0;


$buttonBg: $primaryColor;


$buttonTextColor: $primaryTextColor;


$buttonBorder: 1px solid $primaryColor;


$buttonHoverBg: $primaryDarkColor;


$buttonTextHoverColor: $primaryTextColor;


$buttonHoverBorderColor: $primaryDarkColor;


$buttonActiveBg: $primaryDarkerColor;


$buttonTextActiveColor: $primaryTextColor;


$buttonActiveBorderColor: $primaryDarkerColor;


$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

$roundedButtonBorderRadius: 2rem;


$textButtonHoverBgOpacity:.04;


$textButtonActiveBgOpacity:.16;


$outlinedButtonBorder:1px solid;


$plainButtonTextColor:#6c757d;


$plainButtonHoverBgColor:#e9ecef;


$plainButtonActiveBgColor:#dee2e6;


$secondaryButtonBg: #607D8B;


$secondaryButtonTextColor: #ffffff;


$secondaryButtonBorder: 1px solid #607D8B;


$secondaryButtonHoverBg: #546E7A;


$secondaryButtonTextHoverColor: #ffffff;


$secondaryButtonHoverBorderColor: #546E7A;

$secondaryButtonActiveBg: #455A64;


$secondaryButtonTextActiveColor: #ffffff;


$secondaryButtonActiveBorderColor: #455A64;


$secondaryButtonFocusShadow: 0 0 0 0.2rem #B0BEC5;


$infoButtonBg: #03A9F4;


$infoButtonTextColor: #ffffff;


$infoButtonBorder: 1px solid #03A9F4;


$infoButtonHoverBg: #039BE5;


$infoButtonTextHoverColor: #ffffff;


$infoButtonHoverBorderColor: #039BE5;


$infoButtonActiveBg: #0288D1;


$infoButtonTextActiveColor: #ffffff;


$infoButtonActiveBorderColor: #0288D1;


$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);


$successButtonBg: #4CAF50;


$successButtonTextColor: #ffffff;


$successButtonBorder: 1px solid #4CAF50;


$successButtonHoverBg: #43A047;


$successButtonTextHoverColor: #ffffff;


$successButtonHoverBorderColor: #43A047;


$successButtonActiveBg: #388E3C;


$successButtonTextActiveColor: #ffffff;


$successButtonActiveBorderColor: #388E3C;


$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%);


$warningButtonBg: #F97316;


$closedButtonBg: #6B7280;


$warningButtonTextColor: $textColor;


$warningButtonBorder: 1px solid #F97316;


$warningButtonHoverBg: #FFB300;


$warningButtonTextHoverColor: $textColor;


$warningButtonHoverBorderColor: #FFB300;


$warningButtonActiveBg: #FFA000;


$warningButtonTextActiveColor: $textColor;


$warningButtonActiveBorderColor: #FFA000;


$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);


$helpButtonBg:#9C27B0;


$helpButtonTextColor:#ffffff;


$helpButtonBorder:1px solid #9C27B0;


$helpButtonHoverBg:#8E24AA;


$helpButtonTextHoverColor:#ffffff;


$helpButtonHoverBorderColor:#8E24AA;


$helpButtonActiveBg:#7B1FA2;


$helpButtonTextActiveColor:#ffffff;


$helpButtonActiveBorderColor:#7B1FA2;


$helpButtonFocusShadow:0 0 0 0.2rem #CE93D8;


$dangerButtonBg: #f44336;


$dangerButtonTextColor: #ffffff;


$dangerButtonBorder: 1px solid #f44336;


$dangerButtonHoverBg: #e53935;


$dangerButtonTextHoverColor: #ffffff;


$dangerButtonHoverBorderColor: #e53935;


$dangerButtonActiveBg: #d32f2f;


$dangerButtonTextActiveColor: #ffffff;


$dangerButtonActiveBorderColor: #d32f2f;


$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%);


$linkButtonColor:$primaryDarkerColor;


$linkButtonHoverColor:$primaryDarkerColor;


$linkButtonTextHoverDecoration:underline;


$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;


$toggleButtonBg: #ffffff;


$toggleButtonBorder: 1px solid #ced4da;


$toggleButtonTextColor: $textColor;


$toggleButtonIconColor: $textSecondaryColor;


$toggleButtonHoverBg: #e9ecef;


$toggleButtonHoverBorderColor: #ced4da;


$toggleButtonTextHoverColor: $textColor;


$toggleButtonIconHoverColor: $textSecondaryColor;


$toggleButtonActiveBg: $primaryColor;


$toggleButtonActiveBorderColor: $primaryColor;


$toggleButtonTextActiveColor: $primaryTextColor;


$toggleButtonIconActiveColor: $primaryTextColor;


$toggleButtonActiveHoverBg: $primaryDarkColor;


$toggleButtonActiveHoverBorderColor: $primaryDarkColor;


$toggleButtonTextActiveHoverColor: $primaryTextColor;


$toggleButtonIconActiveHoverColor: $primaryTextColor;


$speedDialButtonWidth: 4rem;


$speedDialButtonHeight: 4rem;


$speedDialButtonIconFontSize: 1.3rem;


$speedDialActionWidth: 3rem;


$speedDialActionHeight: 3rem;


$speedDialActionBg: #495057;


$speedDialActionHoverBg: #343a40;


$speedDialActionTextColor: #fff;


$speedDialActionTextHoverColor: #fff;