.appPageLogin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.appPageLogin__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 24px;

  .appForm {
    width: 100%;
  }

  .p-panel-content {
    font-size: 14px;

    .appForm__col {
      gap: 1em;
    }
  }
}

.appPageLogin__spidBtn.appPageLogin__spidBtn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  gap: 11px;
  background: #06C;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    background: var(--message-info-background);
  }

  span {
    color: #FFF;
    font-size: 17.25px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
  }
}

.reverseSwitchField {
  gap: 1em;

  .appForm__field.switch {
    width: auto;
    
    > .appForm__row {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
  }
}