

$inputPadding: 0.5rem 0.5rem;



$inputBg: #ffffff;



$inputTextFontSize: 1rem;



$inputTextColor: $textColor;



$inputIconColor: $textColor;



$inputBorder: 1px solid #ced4da;



$inputHoverBorderColor: $primaryColor;



$inputFocusBorderColor: $primaryColor;



$inputErrorBorderColor: #ced4da #ced4da #ced4da $errorColor;



$inputPlaceholderTextColor: #6c757d;



$inputFilledBg: #f8f9fa;



$inputFilledHoverBg: #f8f9fa;



$inputFilledFocusBg: #f8f9fa;



$inputGroupBg: #e9ecef;



$inputGroupTextColor: $textSecondaryColor;



$inputGroupAddOnMinWidth: 2.357rem;



$inputListBg: #ffffff;



$inputListTextColor: $textColor;



$inputListBorder: $inputBorder;



$inputListPadding: 0.5rem 0;



$inputListItemPadding: 0.5rem 1rem;



$inputListItemBg: transparent;



$inputListItemTextColor: $textColor;



$inputListItemHoverBg: #e9ecef;



$inputListItemTextHoverColor: $textColor;



$inputListItemTextFocusColor: $textColor;



$inputListItemFocusBg: #f8f9fa;



$inputListItemBorder: 0 none;



$inputListItemBorderRadius: 0;



$inputListItemMargin: 0;



$inputListItemFocusShadow: inset 0 0 0 0.15rem $focusOutlineColor;



$inputListHeaderPadding: 0.5rem 1rem;



$inputListHeaderMargin: 0;



$inputListHeaderBg: #f8f9fa;



$inputListHeaderTextColor: $textColor;



$inputListHeaderBorder: 0 none;



$inputOverlayBg: $inputListBg;



$inputOverlayHeaderBg: $inputListHeaderBg;



$inputOverlayBorder: 0 none;



$inputOverlayShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);



$checkboxWidth: 20px;



$checkboxHeight: 20px;



$checkboxBorder: 2px solid #ced4da;



$checkboxIconFontSize: 14px;



$checkboxActiveBorderColor: $primaryColor;



$checkboxActiveBg: $primaryColor;



$checkboxIconActiveColor: $primaryTextColor;



$checkboxActiveHoverBg: $primaryDarkerColor;



$checkboxIconActiveHoverColor: $primaryTextColor;



$checkboxActiveHoverBorderColor: $primaryDarkerColor;



$radiobuttonWidth: 20px;



$radiobuttonHeight: 20px;



$radiobuttonBorder: 2px solid #ced4da;



$radiobuttonIconSize: 12px;



$radiobuttonActiveBorderColor: $primaryColor;



$radiobuttonActiveBg: $primaryColor;



$radiobuttonIconActiveColor: $primaryTextColor;



$radiobuttonActiveHoverBg: $primaryDarkerColor;



$radiobuttonIconActiveHoverColor: $primaryTextColor;



$radiobuttonActiveHoverBorderColor: $primaryDarkerColor;



$colorPickerPreviewWidth: 2rem;



$colorPickerPreviewHeight: 2rem;



$colorPickerBg: #323232;



$colorPickerBorder: 1px solid #191919;



$colorPickerHandleColor: #ffffff;



$ratingIconFontSize: 1.143rem;



$ratingCancelIconColor: #e74c3c;



$ratingCancelIconHoverColor: #c0392b;



$ratingStarIconOffColor: $textColor;



$ratingStarIconOnColor: $primaryColor;



$ratingStarIconHoverColor: $primaryColor;



$sliderBg: #dee2e6;



$sliderBorder: 0 none;



$sliderHorizontalHeight: 0.286rem;



$sliderVerticalWidth: 0.286rem;



$sliderHandleWidth: 1.143rem;



$sliderHandleHeight: 1.143rem;



$sliderHandleBg: #ffffff;



$sliderHandleBorder: 2px solid $primaryColor;



$sliderHandleBorderRadius: 50%;



$sliderHandleHoverBorderColor: $primaryColor;



$sliderHandleHoverBg: $primaryColor;



$sliderRangeBg: $primaryColor;



$calendarTableMargin: 0.5rem 0;



$calendarPadding: 0.5rem;



$calendarBg: #ffffff;



$calendarInlineBg: $calendarBg;



$calendarTextColor: $textColor;



$calendarBorder: $inputListBorder;



$calendarOverlayBorder: $inputOverlayBorder;



$calendarHeaderPadding: 0.5rem;



$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;



$calendarHeaderBg: #ffffff;



$calendarInlineHeaderBg: $calendarBg;



$calendarHeaderBorder: 1px solid #dee2e6;



$calendarHeaderTextColor: $textColor;



$calendarHeaderFontWeight: 600;



$calendarHeaderCellPadding: 0.5rem;



$calendarCellDatePadding: 0.5rem;



$calendarCellDateWidth: 2.5rem;



$calendarCellDateHeight: 2.5rem;



$calendarCellDateBorderRadius: 50%;



$calendarCellDateBorder: 1px solid transparent;



$calendarCellDateHoverBg: #e9ecef;



$calendarCellDateTodayBg: #ced4da;



$calendarCellDateTodayBorderColor: transparent;



$calendarCellDateTodayTextColor: $textColor;



$calendarButtonBarPadding: 1rem 0;



$calendarTimePickerPadding: 0.5rem;



$calendarTimePickerElementPadding: 0 0.429rem;



$calendarTimePickerTimeFontSize: 1.286rem;



$calendarBreakpoint: 769px;



$calendarCellDatePaddingSM: 0;



$inputSwitchWidth: 3rem;



$inputSwitchHeight: 1.75rem;



$inputSwitchBorderRadius: 30px;



$inputSwitchHandleWidth: 1.25rem;



$inputSwitchHandleHeight: 1.25rem;



$inputSwitchHandleBorderRadius: 50%;



$inputSwitchSliderPadding: 0.25rem;



$inputSwitchSliderOffBg: #ced4da;



$inputSwitchHandleOffBg: #ffffff;



$inputSwitchSliderOffHoverBg: #c3cad2;



$inputSwitchSliderOnBg: $primaryColor;



$inputSwitchSliderOnHoverBg: $primaryDarkColor;



$inputSwitchHandleOnBg: #ffffff;



$fileUploadProgressBarHeight: 0.25rem;



$fileUploadContentPadding: 2rem 1rem;



$editorToolbarBg: #f8f9fa;



$editorToolbarBorder: 1px solid #dee2e6;



$editorToolbarPadding: 1rem;



$editorToolbarIconColor: #6c757d;



$editorToolbarIconHoverColor: #495057;



$editorIconActiveColor: $primaryColor;



$editorContentBorder: 1px solid #dee2e6;



$editorContentBg: #ffffff;



$passwordMeterBg: #dee2e6;



$passwordWeakBg: #e53935;



$passwordMediumBg: #ffb300;



$passwordStrongBg: #43a047;
