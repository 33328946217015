.chartCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-height: 220px;
  padding: 20px 5px;
  border-radius: 6px;
  border: 1px solid #EAB308;
  background: #FFF;
}

.chartCard__title {
  color: var(--global-textColor);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 16px;
}

.chartCard__chart {
  width: 100%;
  height: 24rem;
}

.chartCard__tooltip {
  padding: 5px 10px;
  background-color: white;
  border: 1px solid var(--global-textColor);
}

.chartCard__tooltipTitle {
  font-weight: bold;
}

.chartCard__tooltipText {

}