.notificationsIcon {
  &:hover {
    cursor: pointer;
  }
}

.notificationsSidebar {
  max-width: 360px;
  width: 100%;
}

.notificationsSidebar__loading {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.notificationsSidebar__list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;
  padding: 0;
}

.notificationsSidebar__listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 15px 0;
  border-bottom: 1px solid #e7e7e7;

  &:hover {
    cursor: pointer;
    color: var(--primary-text);
  }
}

.notificationsSidebar__listItemContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
}

.notificationsSidebar__listItemChosen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
