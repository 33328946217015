

$inplacePadding: $inputPadding;



$inplaceHoverBg: #e9ecef;



$inplaceTextHoverColor: $textColor;



$badgeBg: $primaryColor;



$badgeTextColor: $primaryTextColor;



$badgeMinWidth: 1.5rem;



$badgeHeight: 1.5rem;



$badgeFontWeight: 700;



$badgeFontSize: .75rem;



$tagPadding: .25rem .4rem;



$progressBarHeight: 1.5rem;



$progressBarBorder: 0 none;



$progressBarBg: #dee2e6;



$progressBarValueBg: $primaryColor;


$progressBarValueTextColor:$primaryTextColor;


$avatarBg:#dee2e6;



$avatarTextColor:$textColor;



$chipBg:#dee2e6;



$chipTextColor:$textColor;



$chipBorderRadius:16px;



$scrollTopBg:rgba(0,0,0,0.7);



$scrollTopHoverBg:rgba(0,0,0,0.8);



$scrollTopWidth:3rem;



$scrollTopHeight:3rem;



$scrollTopBorderRadius:50%;



$scrollTopFontSize:1.5rem;



$scrollTopTextColor:#f8f9fa;



$skeletonBg:#e9ecef;



$skeletonAnimationBg:rgba(255,255,255,0.4);
