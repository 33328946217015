

$paginatorBg: #ffffff;



$paginatorTextColor: $textSecondaryColor;



$paginatorBorder: solid #e9ecef;



$paginatorBorderWidth: 0;



$paginatorPadding: .5rem 1rem;



$paginatorElementWidth: $buttonIconOnlyWidth;



$paginatorElementHeight: $buttonIconOnlyWidth;



$paginatorElementBg: transparent;



$paginatorElementBorder: 0 none;



$paginatorElementIconColor: $textSecondaryColor;



$paginatorElementHoverBg: #e9ecef;



$paginatorElementHoverBorderColor: transparent;



$paginatorElementIconHoverColor: $textSecondaryColor;



$paginatorElementBorderRadius: $borderRadius;



$paginatorElementMargin: .143rem;



$paginatorElementPadding: 0;



$tableHeaderBorder: 1px solid #e9ecef;



$tableHeaderBorderWidth: 0 0 1px 0;



$tableHeaderBg: #f8f9fa;



$tableHeaderTextColor: $textColor;



$tableHeaderFontWeight: 600;



$tableHeaderPadding: 1rem 1rem;



$tableHeaderCellPadding: 1rem 1rem;



$tableHeaderCellBg: #f8f9fa;



$tableHeaderCellTextColor: $textColor;



$tableHeaderCellFontWeight: 600;



$tableHeaderCellBorder: 1px solid #e9ecef;



$tableHeaderCellBorderWidth: 0 0 1px 0;



$tableHeaderCellHoverBg: #e9ecef;



$tableHeaderCellTextHoverColor: $textColor;



$tableHeaderCellIconColor: $textSecondaryColor;



$tableHeaderCellIconHoverColor: $textSecondaryColor;



$tableHeaderCellHighlightBg:#f8f9fa;



$tableHeaderCellHighlightTextColor:$primaryColor;



$tableHeaderCellHighlightHoverBg:#e9ecef;



$tableHeaderCellHighlightTextHoverColor:$primaryColor;



$tableSortableColumnBadgeSize: 1.143rem;



$tableBodyRowBg: #ffffff;



$tableBodyRowTextColor: $textColor;



$tableBodyRowEvenBg: #ffffff;



$tableBodyRowHoverBg: #e9ecef;



$tableBodyRowTextHoverColor: $textColor;



$tableBodyCellBorder: 1px solid rgba(0,0,0,0.08);



$tableBodyCellBorderWidth: 0 0 1px 0;



$tableBodyCellPadding: 1rem 1rem;



$tableFooterCellPadding: 1rem 1rem;



$tableFooterCellBg: #f8f9fa;



$tableFooterCellTextColor: $textColor;



$tableFooterCellFontWeight: 600;






$tableFooterCellBorder: 1px solid #e9ecef;



$tableFooterCellBorderWidth: 0 0 1px 0;



$tableResizerHelperBg: $primaryColor;



$tableDragHelperBg: rgba($primaryColor, .16);



$tableFooterBorder: 1px solid #e9ecef;



$tableFooterBorderWidth: 0 0 1px 0;



$tableFooterBg: #f8f9fa;



$tableFooterTextColor: $textColor;



$tableFooterFontWeight: 600;



$tableFooterPadding: 1rem 1rem;



$tableCellContentAlignment: left;



$tableTopPaginatorBorderWidth: 1px 0 1px 0;



$tableBottomPaginatorBorderWidth: 0 0 1px 0;



$tableScaleSM: 0.5;



$tableScaleLG: 1.25;



$dataViewContentPadding: 0;



$dataViewContentBorder: 0 none;



$dataViewListItemBorder: solid #e9ecef;



$dataViewListItemBorderWidth: 0 0 1px 0;



$dataScrollerContentPadding: 0;



$dataScrollerContentBorder: 0 none;



$dataScrollerListItemBorder: solid #e9ecef;



$dataScrollerListItemBorderWidth: 0 0 1px 0;



$treeContainerPadding: 0.286rem;



$treeNodePadding: 0.143rem;



$treeNodeContentPadding: .5rem;



$treeNodeChildrenPadding: 0 0 0 1rem;



$treeNodeIconColor: $textSecondaryColor;



$timelineVerticalEventContentPadding:0 1rem;



$timelineHorizontalEventContentPadding:1rem 0;



$timelineEventMarkerWidth:1rem;



$timelineEventMarkerHeight:1rem;



$timelineEventMarkerBorderRadius:50%;



$timelineEventMarkerBorder:2px solid $highlightBg;



$timelineEventMarkerBackground:$highlightTextColor;



$timelineEventConnectorSize:2px;



$timelineEventColor:#dee2e6;



$organizationChartConnectorColor: #dee2e6;