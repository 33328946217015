

$carouselIndicatorsPadding: 1rem;



$carouselIndicatorBg: #e9ecef;



$carouselIndicatorHoverBg: #dee2e6;



$carouselIndicatorBorderRadius: 0;



$carouselIndicatorWidth: 2rem;



$carouselIndicatorHeight: .5rem;



$galleriaMaskBg: rgba(0,0,0,0.9);



$galleriaCloseIconMargin: .5rem;



$galleriaCloseIconFontSize: 2rem;



$galleriaCloseIconBg: transparent;



$galleriaCloseIconColor: #ebedef;



$galleriaCloseIconHoverBg: rgba(255,255,255,0.1);



$galleriaCloseIconHoverColor: #ebedef;



$galleriaCloseIconWidth: 4rem;



$galleriaCloseIconHeight: 4rem;



$galleriaCloseIconBorderRadius: 50%;



$galleriaItemNavigatorBg: rgba(0,0,0,.2);



$galleriaItemNavigatorColor: #aeb6bf;



$galleriaItemNavigatorMargin: .5rem 0;



$galleriaItemNavigatorFontSize: 2rem;



$galleriaItemNavigatorHoverBg: rgba(0,0,0,.3);



$galleriaItemNavigatorHoverColor: #ebedef;



$galleriaItemNavigatorWidth: 4rem;



$galleriaItemNavigatorHeight: 4rem;



$galleriaItemNavigatorBorderRadius: $borderRadius;



$galleriaCaptionBg: rgba(0,0,0,.5);



$galleriaCaptionTextColor: #ebedef;



$galleriaCaptionPadding: 1rem;



$galleriaIndicatorsPadding: 1rem;



$galleriaIndicatorBg: #e9ecef;



$galleriaIndicatorHoverBg: #dee2e6;



$galleriaIndicatorBorderRadius: 50%;



$galleriaIndicatorWidth: 1rem;



$galleriaIndicatorHeight: 1rem;



$galleriaIndicatorsBgOnItem: rgba(0,0,0,.5);



$galleriaIndicatorBgOnItem: rgba(255,255,255,.4);



$galleriaIndicatorHoverBgOnItem: rgba(255,255,255,.6);



$galleriaThumbnailContainerBg: rgba(0,0,0,.9);



$galleriaThumbnailContainerPadding: 1rem .25rem;



$galleriaThumbnailNavigatorBg: transparent;



$galleriaThumbnailNavigatorColor: #aeb6bf;



$galleriaThumbnailNavigatorHoverBg: rgba(255,255,255,0.1);



$galleriaThumbnailNavigatorHoverColor: #aeb6bf;



$galleriaThumbnailNavigatorBorderRadius: 50%;



$galleriaThumbnailNavigatorWidth: 2rem;



$galleriaThumbnailNavigatorHeight: 2rem;



$imageMaskBg:rgba(0,0,0,0.9) !default;



$imagePreviewToolbarPadding:1rem !default;



$imagePreviewIndicatorColor:#f8f9fa !default;



$imagePreviewIndicatorBg:rgba(0,0,0,0.5) !default;



$imagePreviewActionIconBg:transparent !default;



$imagePreviewActionIconColor:#f8f9fa !default;



$imagePreviewActionIconHoverBg:rgba(255,255,255,0.1) !default;



$imagePreviewActionIconHoverColor:#f8f9fa !default;



$imagePreviewActionIconWidth:3rem !default;



$imagePreviewActionIconHeight:3rem !default;



$imagePreviewActionIconFontSize:1.5rem !default;



$imagePreviewActionIconBorderRadius:50% !default;
