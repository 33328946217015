.statsBigBadges {
}

.statsBigBadges__grid {
    display: grid;
    align-items: stretch;
    /*grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));*/
    grid-template-columns: repeat(2, minmax(220px, 1fr));
    gap: 1rem;
    width: 100%;
    container-name: big-badges-grid;
    container-type: inline-size;

    &.grid-small {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
}

.statsBigBadges__grid .statsBigBadges__gridItem span {
    color: #FFF;
}

.statsBigBadges__gridItem {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #858585;
    background: #cecece;
    align-items: center;
    gap: 48px;

    span {
        color: #FFF;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    span:nth-last-of-type(1) {
        font-size: 22px;
    }
}

.statsBigBadges__gridItemDoubleStats {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #858585;
    background: #cecece;
    align-items: center;
    gap: 32px;

    span {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }

    > span:first-of-type {
        min-height: 50px;
    }

    span.number {
        font-size: 22px;
    }

    .auxStats {
        display: flex;
        gap: 7px;
        align-items: flex-start;
        margin-top: auto;

        span {
            font-size: 15px;
            font-weight: 400;
            text-align: left;
        }
        
        span.badge {
            display: inline-block;
            padding: 5px 10px;
            background-color: var(--card-full-background-color-4);
            border-radius: 4px;
            margin-right: 7px;
        }
    }

    &:nth-of-type(1) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-2);
    }

    &:nth-of-type(2) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-3);
    }

    &:nth-of-type(3) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-5);
    }

    &:nth-of-type(4) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-7);
    }
}

.statsBigBadges__gridItemDoubleStatsBeneficiary {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #858585;
    background: #cecece;
    align-items: center;
    gap: 32px;

    span {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }

    > span:first-of-type {
        min-height: 50px;
    }

    &:nth-of-type(1) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-2);
    }

    &:nth-of-type(2) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-4);
    }

    &:nth-of-type(3) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-3);
    }

    &:nth-of-type(4) {
        border: 1px solid var(--yellow-500);
        background: var(--card-full-background-color-1);
    }
}

.statsBigBadges__grid {
    .statsBigBadges__gridItem {
        &:nth-of-type(1) {
            border: 1px solid var(--yellow-500);
            background: var(--card-full-background-color-2);
        }

        &:nth-of-type(2) {
            border: 1px solid var(--yellow-500);
            background: var(--card-full-background-color-3);
        }

        &:nth-of-type(3) {
            border: 1px solid var(--yellow-500);
            background: var(--card-full-background-color-4);
        }

        &:nth-of-type(4) {
            border: 1px solid var(--yellow-500);
            background: var(--card-full-background-color-1);
        }

        &:nth-of-type(5) {
            border: 1px solid var(--yellow-500);
            background: var(--card-full-background-color-5);
        }

        &:nth-of-type(6) {
            border: 1px solid var(--yellow-500);
            background: var(--card-full-background-color-6);
        }
    }

    &.applStats {
        .statsBigBadges__gridItem {
            &:nth-of-type(1) {
                border: 1px solid var(--yellow-500);
                background: var(--card-full-background-color-2);
            }

            &:nth-of-type(2) {
                border: 1px solid var(--yellow-500);
                background: var(--card-full-background-color-3);
            }

            &:nth-of-type(3) {
                border: 1px solid var(--yellow-500);
                background: var(--card-full-background-color-4);
            }

            &:nth-of-type(4) {
                border: 1px solid var(--yellow-500);
                background: var(--card-full-background-color-7);
            }

            &:nth-of-type(5) {
                border: 1px solid var(--yellow-500);
                background: var(--card-full-background-color-8);
            }

            &:nth-of-type(6) {
                border: 1px solid var(--yellow-500);
                background: var(--card-full-background-color-9);
            }
        }
    }

    &.doubleStatsItems {
        grid-template-columns: repeat(4, minmax(220px, 1fr));
    }
}

@media (max-width: 1290px) {
    .statsBigBadges__grid {
        &.doubleStatsItems {
            grid-template-columns: repeat(2, minmax(220px, 1fr));
        }
    }
}

@media (max-width: 820px) {
    .statsBigBadges__grid {
        grid-template-columns: 1fr;

        &.doubleStatsItems {
            grid-template-columns: minmax(220px, 1fr);
        }
    }
}

@container big-badges-grid (max-width: 700px) {
    .statsBigBadges__gridItem {
        padding: 12px;

        span {
            font-size: 16px;
        }
        span:nth-last-of-type(1) {
            font-size: 16px;
        }
    }
}