.fieldsRepeater {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.fieldsRepeater form {
  display: contents;

}

.fieldsRepeater__panel {
  width: 100%;
}

.fieldsRepeater__heading {
  align-items: center;
  display: flex;
  justify-content: space-between;

  > span {
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
      color: var(--menuitem-active-background);
    }
  }

  &[data-hide="true"] {
    display: none;
  }
}

.fieldsRepeater__fields {
  &[data-hide="true"] {
    display: none;
  }
}

.fieldsRepeater__addNew {
}