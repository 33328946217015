.formBuilder {
  position: relative;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 600px;
}

.formBuilder__main {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: #404D5B;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.formBuilder__content {
  height: 100%;
  overflow-y: scroll;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 20px;
  overscroll-behavior: contain;
  border: 1px #DDD;
  background: var(--surface-50);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.10);
  
  .dropzone {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border: 3px dashed #bdbdbd;
  }
}

.formBuilder__element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 10px 20px;
  border: 1px solid var(--button-secondary-borderColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  &:hover {
    cursor: pointer;
  }

  /*&.selected {
    border-color: var(--menuitem-active-background);
  }*/

  .meta {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    
    .tagHeader {
      display: flex;
      gap: 10px;
    }
  }

  .label {
    p {
      margin-bottom: 0;

      &.ql-indent-1 {
        padding-left: 3em;
      }
      &.ql-indent-2 {
        padding-left: 6em;
      }
      &.ql-indent-3 {
        padding-left: 9em;
      }
      &.ql-indent-4 {
        padding-left: 12em;
      }
      &.ql-indent-5 {
        padding-left: 15em;
      }
      &.ql-indent-6 {
        padding-left: 18em;
      }
      &.ql-indent-7 {
        padding-left: 21em;
      }
    }

    h2 {
      margin-bottom: 15px;
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.2em;
      margin-bottom: 10px;
    }
    
    li[data-list="bullet"] {
      list-style: disc;
    }
  }

  .actions {
    display: flex;
    gap: 0.5rem;
  }
}

.formBuilder__elementNew.formBuilder__elementNew {
  border: 1px solid var(--button-secondary-borderColor);
  background-color: var(--button-secondary-borderColor);
  width: 100%;
  padding: 20px 10px;
  opacity: 0.6;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}

.formBuilder__aside {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;

  h2 {
    color: #404D5B;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.formBuilder__list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior: contain;
}
.formBuilder__elementItem {
  display: flex;
  padding: 10px 20px;
  border: 1px dashed var(--button-secondary-borderColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  &:hover {
    cursor: grab;
    background-color: #F4E8C3;
  }
}

.formBuilder__elementSettings {
  width: 40rem;
}

.formElementSettings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.formElementSettings__field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formElementSettings__fieldDescription, .formElementSettings__fieldVarsList {
  padding: 15px;
  background-color: #ffe0c5;
  border: 1px solid #e6a973;
  
  p {
    margin: 0;
    color: #c68e5e;
    font-size: 15px;
    line-height: 1.5;
  }

  code {
    font-size: 14px;
    padding: 3px 6px;
    border: 1px solid #e1b48b;
    background-color: #fbeadb;
    border-radius: 3px;
    user-select: all;
  }
}

.formElementSettings__fieldVarsList {
  background-color: #e7fddd;
  border: 1px solid #9de673;

  p {
    color: #5eae30;
  }

  code {
    border: 1px solid #9de673;
    background-color: #effaea;
    margin-right: 4px;
    user-select: all;
  }
}

.formElementSettings__tabs {
  width: 100%;

  .p-tabview-nav-link[aria-selected="true"] {
    background-color: var(--message-info-background);
  }

  .p-tabview-panel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.formElementSettings__repeater {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.formElementSettings__repeaterItem {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  &.tableRow {
    grid-template-columns: 4.5fr 2.4fr 1fr 1.4fr 0.7fr;
  }

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    input, select, .p-dropdown {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.formElementSettings__subRepeaterWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 7px;
}

.formElementSettings__subRepeaterItem {
  display: grid;
  grid-template-columns: 9.3fr 0.7fr;
  gap: 12px;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    input, select, .p-dropdown {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.formElementSettings__repeaterItemIconBtn {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 2rem;
  color: var(--table-border-color);

  i {
    font-size: 2rem;
  }

  &:hover {
    cursor: pointer;
    color: var(--menuitem-active-background);
  }

  &:not([data-active="false"]) {
    color: var(--menuitem-active-background);
  }
}

.formElementSettings__lastRowHeader {
  display: grid;
  grid-template-columns: 8.3fr 1.7fr;
  gap: 7px;
}

.formElementSettings__lastRowHeaderTitle {
  display: flex;
  height: 40px;
  padding: 10.5px 17.5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.formElementSettings__lastRowItem {
  display: grid;
  grid-template-columns: 1.7fr 8.3fr;
  gap: 7px;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    input, select, .p-dropdown {
      width: 100%;
      box-sizing: border-box;
    }
  }
}