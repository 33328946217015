

$stepsItemBg: #ffffff;



$stepsItemBorder: 1px solid #c8c8c8;



$stepsItemTextColor: $textSecondaryColor;



$stepsItemNumberWidth: 2rem;



$stepsItemNumberHeight: 2rem;



$stepsItemNumberFontSize: 1.143rem;



$stepsItemNumberColor: $textColor;



$stepsItemNumberBorderRadius: 50%;



$stepsItemActiveFontWeight: 600;



$menuWidth: 12.5rem;



$menuBg: #ffffff;



$menuBorder: 1px solid #dee2e6;



$menuTextColor: $textColor;



$menuitemPadding: 0.75rem 1rem;



$menuitemBorderRadius: 0;



$menuitemTextColor: $textColor;



$menuitemIconColor: $textSecondaryColor;



$menuitemTextHoverColor: $textColor;



$menuitemIconHoverColor: $textSecondaryColor;



$menuitemHoverBg: #e9ecef;



$menuitemTextFocusColor: $textColor;



$menuitemIconFocusColor: $textColor;



$menuitemFocusBg: #f8f9fa;



$menuitemTextActiveColor: $textColor;



$menuitemIconActiveColor: $textSecondaryColor;



$menuitemActiveBg: #e9ecef;



$menuitemActiveFocusBg: #e9ecef;



$menuitemSubmenuIconFontSize: 0.875rem;



$submenuHeaderMargin: 0;



$submenuHeaderPadding: 0.75rem 1rem;



$submenuHeaderBg: #ffffff;



$submenuHeaderTextColor: $textColor;



$submenuHeaderBorderRadius: 0;



$submenuHeaderFontWeight: 600;



$overlayMenuBg: $menuBg;



$overlayMenuBorder: 0 none;



$overlayMenuShadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);



$verticalMenuPadding: 0.25rem 0;



$menuSeparatorMargin: 0.25rem 0;



$breadcrumbPadding: 1rem;



$breadcrumbBg: $menuBg;



$breadcrumbBorder: $menuBorder;



$breadcrumbItemTextColor: $menuitemTextColor;



$breadcrumbItemIconColor: $menuitemIconColor;



$breadcrumbLastItemTextColor: $menuitemTextColor;



$breadcrumbLastItemIconColor: $menuitemIconColor;



$breadcrumbSeparatorColor: $menuitemTextColor;



$horizontalMenuPadding: 0.5rem;



$horizontalMenuBg: #f8f9fa;



$horizontalMenuBorder: $menuBorder;



$horizontalMenuTextColor: $menuTextColor;



$horizontalMenuRootMenuitemPadding: $menuitemPadding;



$horizontalMenuRootMenuitemBorderRadius: $borderRadius;



$horizontalMenuRootMenuitemTextColor: $menuitemTextColor;



$horizontalMenuRootMenuitemIconColor: $menuitemIconColor;



$horizontalMenuRootMenuitemTextHoverColor: $menuitemTextHoverColor;



$horizontalMenuRootMenuitemIconHoverColor: $menuitemIconHoverColor;



$horizontalMenuRootMenuitemHoverBg: $menuitemHoverBg;



$horizontalMenuRootMenuitemTextActiveColor: $menuitemTextActiveColor;



$horizontalMenuRootMenuitemIconActiveColor: $menuitemIconActiveColor;



$horizontalMenuRootMenuitemActiveBg: $menuitemActiveBg;



$dockActionWidth: 4rem;



$dockActionHeight: 4rem;



$dockItemPadding: 0.5rem;



$dockItemBorderRadius: $borderRadius;



$dockCurrentItemMargin: 1.5rem;



$dockFirstItemsMargin: 1.3rem;



$dockSecondItemsMargin: 0.9rem;



$dockBg: rgba(255, 255, 255, 0.1);



$dockBorder: 1px solid rgba(255, 255, 255, 0.2);



$dockPadding: 0.5rem 0.5rem;



$dockBorderRadius: 0.5rem;
