.p-dialog {
    border-radius: $borderRadius;
    box-shadow: $overlayContainerShadow;
    border: $overlayContentBorder;

    .p-dialog-header {
        border-bottom: $dialogHeaderBorder;
        background: $dialogHeaderBg;
        color: $dialogHeaderTextColor;
        padding: $dialogHeaderPadding;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-dialog-title {
            font-weight: $dialogHeaderFontWeight;
            font-size: $dialogHeaderFontSize;
        }

        .p-dialog-header-icon {
            margin-right: $inlineSpacing;
            @include action-icon();
            
            &:last-child {
                margin-right: 0;
            }            
        }
    }

    .p-dialog-content {
        background: $overlayContentBg;
        color: $panelContentTextColor;
        padding: $dialogContentPadding;

        &:last-of-type {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }
    }

    .p-dialog-footer {
        border-top:  $dialogFooterBorder;
        background: $overlayContentBg;
        color: $panelFooterTextColor;
        padding: $dialogFooterPadding;
        text-align: right;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;

        button {
            margin: 0 $inlineSpacing 0 0;
            width: auto;
        }
    }

    &.p-dialog-maximized {
        .p-dialog-header, .p-dialog-content:last-of-type {
            border-radius: 0;
        }
    }
    

    &.p-confirm-dialog {
        .p-confirm-dialog-icon {
            font-size: $primeIconFontSize * 2;
            
            &.p-icon {
                width: $primeIconFontSize * 2;
                height: $primeIconFontSize * 2;
            }
        }

        .p-confirm-dialog-message {
            margin-left: $inlineSpacing * 2;
        }
    }
}
