

$overlayContentBorder: 0 none;



$overlayContentBg:$panelContentBg;



$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);



$dialogHeaderBg: #ffffff;



$dialogHeaderBorder: 1px solid #dee2e6;



$dialogHeaderTextColor: $panelHeaderTextColor;



$dialogHeaderFontWeight: 600;



$dialogHeaderFontSize: 1.25rem;



$dialogHeaderPadding: 1.5rem;



$dialogContentPadding: 0 1.5rem;



$dialogFooterBorder: 1px solid #dee2e6;



$dialogFooterPadding: 1.5rem;



$confirmPopupContentPadding:$panelContentPadding;



$confirmPopupFooterPadding:0 1rem 1rem 1rem;



$tooltipBg: $textColor;



$tooltipTextColor: #ffffff;



$tooltipPadding: $inputPadding;