.error404 {
  display: flex;
  flex-grow: 2;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {

  }

  span {

  }
}