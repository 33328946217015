html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  font-family: "Montserrat", sans-serif;

  /*p, span:not(.p-button-label, .p-button-icon, .p-badge, .p-message-detail, .p-message-summary, .p-highlight, .p-inline-message-text, .p-tag, .p-tag-icon),
  input:not(.p-checkbox-input),
  label:not(.p-error), textarea, a, li, h1, h2, h3, h4, h5, h6, div:not(.p-inline-message, .p-toast-detail, .p-checkbox-box), th, td,
  :not(svg, path, .p-button, .p-button > span, .statsBigBadges__gridItem > span, number-flow, .p-paginator-page, .p-badge) {
    color: var(--global-textColor);
  }*/

  h2, h3, p, label, .appPageSection__hr, li {
    color: var(--global-textColor);
  }

  textarea {
    max-width: 100%;
  }
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

#root, .wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

iframe {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

.appLogo {
  height: 150px;
}

.inner {
  display: flex;
  height: 100%;
  min-height: 0;
  flex-grow: 1;

  > aside {
    flex: 0 0 320px;
    border-right: 1px solid var(--menu-borderColor);

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;

      li a, li button {
        display: flex;
        padding: 10.5px 17.5px;
        align-items: center;
        gap: 7px;
        width: 100%;
        border: 0;
        border-bottom: 1px solid var(--menu-borderColor);
        color: var(--menuitem-color);
        background-color: transparent;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }

        i {
          color: var(--text-color-secondary);
        }

        &.active {
          color: var(--menuitem-active-color);
          background-color: var(--menuitem-active-background);

          i {
            color: white;
          }

          svg {
            path {
              fill: white;
            }
          }

          span {
            color: var(--menuitem-active-color);
          }
        }
      }

      li div.nonLink {
        display: flex;
        padding: 10.5px 17.5px;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-decoration: none;
        border-bottom: 1px solid var(--menu-borderColor);
        background-color: var(--button-secondary-borderColor);
        color: white;
      }
    }
  }

  > main {
    flex: 1 1 auto;
    box-sizing: border-box;
    padding: 0 24px 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 320px);

    > footer {
      margin-top: auto;
      padding: 100px 0 0;

      p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.blockingOverlay {
  position: absolute;
  z-index: 999;
  inset: 0;
  background-color: rgba(255,255,255,0.3)
}

button[disabled] {
  filter: grayscale(1);
}

.iconBtn {
  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    color: var(--primary-text);
  }
}

@media (max-width: 800px) {
  .inner {
    flex-direction: column;

    > aside {
      flex: 0 0 auto;
      margin-bottom: 50px;
    }

    > main {
      width: 100%;
    }
  }
}