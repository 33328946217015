

$messageMargin: 1rem 0;



$messagePadding: 1rem 1.5rem;



$messageBorderWidth: 0 0 0 4px;



$messageIconFontSize: 1.5rem;



$messageTextFontSize: 1rem;



$messageTextFontWeight: 500;



$inlineMessagePadding: $inputPadding;



$inlineMessageMargin: 0;



$inlineMessageIconFontSize: 1rem;



$inlineMessageTextFontSize: 1rem;



$inlineMessageBorderWidth: 1px;



$toastIconFontSize: 2rem;



$toastMessageTextMargin: 0 0 0 1rem;



$toastMargin: 0 0 1rem 0;



$toastPadding: 1rem;



$toastBorderWidth: 0 0 0 4px;



$toastShadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);



$toastOpacity: .9;



$toastTitleFontWeight: 700;



$toastDetailMargin: $inlineSpacing 0 0 0;



$infoMessageBg: #039BE5;



$infoMessageBorder: solid #027cb7;



$infoMessageTextColor: #ffffff;



$infoMessageIconColor: #ffffff;



$successMessageBg: #43A047;



$successMessageBorder: 0 none;



$successMessageTextColor: #ffffff;



$successMessageIconColor: #ffffff;



$warningMessageBg: #FFB300;



$warningMessageBorder: 0 none;



$warningMessageTextColor: $textColor;



$warningMessageIconColor: $textColor;



$errorMessageBg: #E53935;



$errorMessageBorder: 0 none;



$errorMessageTextColor: #ffffff;



$errorMessageIconColor: #ffffff;