.criterionRelatedData {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 400px;
  overflow-y: auto;
  padding: 0 0 10px 0;

  > h3 {
    margin: 0;
  }
}

.criterionRelatedData__item {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 10px;
  background-color: #f5f5f5;
  font-size: 14px;

  ul {
    margin: 0;
    padding-left: 15px;

    li a {
      text-decoration: underline;
      color: var(--global-textColor);
    }
  }

  table, th, td {
    border: 1px solid var(--table-border-color);
    border-collapse: collapse;
    text-align: left;
  }
  
  th, td {
    padding: 3px;
  }
}