/*@import 'primereact/resources/themes/lara-light-green/theme.css';*/
@import "gepafin/gepafin-theme.scss";
@import 'primeicons/primeicons.css';

:root {
  --menu-borderColor: #EAB308;
  --horizontalMenu-background: #F9FAFB;
  --menuitem-color: #4B5563;
  --menuitem-active-color: #FFF;
  --menuitem-active-background: #3B7C43;
  --Black: #000;
  --card-borderColor-color: #EEC137;
  --button-secondary-borderColor: #C79807;
  --button-secondary-background: var(--menu-borderColor);
  --global-textColor: #4B5563;
  --theme-highlight-background: #BADEBE;
  --primary-text: #3B7C43;
  --table-border-color: #B7B7B7B2;
  --message-error-background: #ffdbdb;
  --message-error-color: #C2504D;
  --message-warning-color: #cc8925;
  --message-info-background: rgba(183, 183, 183, 0.7);
  --message-info-color: #3B82F6;
  --panel-content-borderColor: #E5E7EB;

  --card-full-background-color-2: #EEC137;
  --card-full-background-color-3: #FA8E42;
  --card-full-background-color-4: #75BD7D;
  --card-full-background-color-1: #54965b;
  --card-full-background-color-5: #4ba190;
  --card-full-background-color-6: #6d68c0;
  --card-full-background-color-7: #06B6D4;
  --card-full-background-color-8: #FF3D32;
  --card-full-background-color-9: #F97316;
}

@import "./components/layout.scss";
@import "./components/topBar.scss";
@import "./components/appPage.scss";
@import "./components/statsBigBadges.scss";
@import "./components/bandoStatusTag.scss";
@import "./components/appForm.scss";
@import "./components/pageBando.scss";
@import "./components/formBuilder.scss";
@import "./components/misc.scss";
@import "./components/login.scss";
@import "./components/flowBuilder.scss";
@import "./components/error404.scss";
@import "./components/myTable.scss";
@import "./components/evaluation.scss";
@import "./components/fieldsRepeater.scss";
@import "./components/notificationsSidebar.scss";
@import "./components/charts.scss";
