.flowBuilder__wrapper {
  width: 100%;
  height: 500px;
}

.nodeInitialForm {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-size: 13px;
    text-align: center;
  }
}

.nodeIntermediateForm {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 180px;

  label {
    font-size: 13px;
    text-align: center;
  }
}
.flowContainer {
  width: 100%;
  overflow-x: auto;
  margin-top: 30px;
}

.flowContainerInner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 0 auto;
}

.flowContainer__level {
  display: flex;
  justify-content: center;
  gap: 20px;
  /*min-height: 240px;*/
  /*margin: 0 auto;*/

  &.initialLevel {
    padding: 0 0 30px;
  }

  &.intermediateLevel, &.finalLevel {
    padding: 30px 0 30px;
  }

  &.intermediateLevel {
    border-bottom: 1px solid var(--table-border-color);
    border-top: 1px solid var(--table-border-color);
  }
}

.flowContainer__flowItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 280px;
  min-width: 280px;
  padding: 15px;
  border: 1px solid var(--table-border-color);
  z-index: 9;

  .flowContainer__flowItemInner > label {
    border-color: #757575;
    background-color: #757575;
    color: white;
  }

  &.initialForm, &.finalForm {
    .flowContainer__flowItemInner > label {
      border-color: var(--card-full-background-color-3);
      background-color: var(--card-full-background-color-3);
      color: white;
    }
  }

  &.levelForms, &.initialForm {
    &:after {
      position: absolute;
      bottom: -31px;
      left: 50%;
      content: '';
      width: 1px;
      height: 31px;
      background-color: var(--table-border-color);
    }
  }

  &.levelForms, &.finalForm {
    &:before {
      position: absolute;
      top: -31px;
      left: 50%;
      content: '';
      width: 1px;
      height: 31px;
      background-color: var(--table-border-color);
    }
  }
}

.flowContainer__levelMaskStart, .flowContainer__levelMaskEnd {
  position: absolute;
  width: 140px;
  height: 100% ;
  top: -1px;
  background-color: white;
}
.flowContainer__levelMaskStart {
  left: 0;
}
.flowContainer__levelMaskEnd {
  right: 0;
}

.flowContainer__flowItemInner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 250px;
  border: 1px solid var(--panel-content-borderColor);
  height: 100%;

  > label {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
}

.flowContainer__flowItemContent {
  padding: 5px;
  display: flex;
  flex-direction: column;
  
  .appForm__field {
    margin-top: 10px;
    
    label {
      text-align: center;
    }
  }
}