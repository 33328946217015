

$panelHeaderBorderColor: #dee2e6;



$panelHeaderBorder: 1px solid #dee2e6;



$panelHeaderBg: #f8f9fa;



$panelHeaderTextColor: $textColor;



$panelHeaderFontWeight: 600;



$panelHeaderPadding: 1rem;



$panelToggleableHeaderPadding: .5rem 1rem;




$panelContentBorderColor: #dee2e6;



$panelContentBorder: 1px solid #dee2e6;



$panelContentBg: #ffffff;



$panelContentEvenRowBg: #e9ecef;



$panelContentTextColor: $textColor;



$panelContentPadding: 1rem;



$panelFooterBorder: 1px solid #dee2e6;



$panelFooterBg: #ffffff;



$panelFooterTextColor: $textColor;



$panelFooterPadding: 0.5rem 1rem;



$accordionSpacing: 0;



$accordionHeaderBorder: $panelHeaderBorder;



$accordionHeaderBg: $panelHeaderBg;



$accordionHeaderTextColor: $panelHeaderTextColor;



$accordionHeaderFontWeight: $panelHeaderFontWeight;



$accordionHeaderPadding: $panelHeaderPadding;



$accordionHeaderHoverBg: #e9ecef;



$accordionHeaderHoverBorderColor: $panelHeaderBorder;



$accordionHeaderTextHoverColor: $textColor;



$accordionHeaderActiveBg: $panelHeaderBg;



$accordionHeaderActiveBorderColor: #dee2e6;



$accordionHeaderTextActiveColor: $textColor;



$accordionHeaderActiveHoverBg: #e9ecef;



$accordionHeaderActiveHoverBorderColor: #dee2e6;



$accordionHeaderTextActiveHoverColor: $textColor;



$accordionContentBorder: $panelContentBorder;



$accordionContentBg: $panelContentBg;



$accordionContentTextColor: $panelContentTextColor;



$accordionContentPadding: $panelContentPadding;



$tabviewNavBorder: 1px solid #dee2e6;



$tabviewNavBorderWidth: 0 0 2px 0;



$tabviewNavBg: #ffffff;



$tabviewHeaderSpacing: 0;



$tabviewHeaderBorder: solid #dee2e6;



$tabviewHeaderBorderWidth: 0 0 2px 0;



$tabviewHeaderBorderColor: transparent transparent #dee2e6 transparent;



$tabviewHeaderBg: #ffffff;



$tabviewHeaderTextColor: $textSecondaryColor;



$tabviewHeaderFontWeight: $panelHeaderFontWeight;



$tabviewHeaderPadding: $panelHeaderPadding;



$tabviewHeaderMargin: 0 0 -2px 0;



$tabviewHeaderHoverBg: #ffffff;



$tabviewHeaderHoverBorderColor: #9ba2aa;



$tabviewHeaderTextHoverColor: $textSecondaryColor;



$tabviewHeaderActiveBg: #ffffff;



$tabviewHeaderActiveBorderColor: $primaryColor;



$tabviewHeaderTextActiveColor: $primaryColor;



$tabviewContentBorder: 0 none;



$tabviewContentBg: $panelContentBg;



$tabviewContentTextColor: $panelContentTextColor;



$tabviewContentPadding: $panelContentPadding;



$panelHeaderHoverBg: #e9ecef;



$panelHeaderHoverBorderColor: #dee2e6;



$panelHeaderTextHoverColor: $textColor;



$scrollPanelTrackBorder: 0 none;



$scrollPanelTrackBg: #f8f9fa;



$cardBodyPadding: 1rem;



$cardTitleFontSize: 1.5rem;



$cardTitleFontWeight: 700;



$cardSubTitleFontWeight: 700;



$cardSubTitleColor: $textSecondaryColor;



$cardContentPadding: 1rem 0;



$cardFooterPadding: 1rem 0 0 0;



$cardShadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0,0,0,.12);



$dividerHorizontalMargin:1rem 0;



$dividerHorizontalPadding:0 1rem;



$dividerVerticalMargin:0 1rem;



$dividerVerticalPadding:1rem 0;



$dividerSize:1px;



$dividerColor:#dee2e6;



$splitterGutterBg:#f8f9fa;



$splitterGutterHandleBg:#dee2e6;